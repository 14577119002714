<template>
    <div class="height-center">
        <div v-if="sessionsError == false">
            <div id="loading-spinner">
                <div class="loader contener_general">
                    <div class="contener_mixte"><div class="ballcolor ball_1">&nbsp;</div></div>
                    <div class="contener_mixte"><div class="ballcolor ball_2">&nbsp;</div></div>
                    <div class="contener_mixte"><div class="ballcolor ball_3">&nbsp;</div></div>
                    <div class="contener_mixte"><div class="ballcolor ball_4">&nbsp;</div></div>
                </div>
                <div>
                    <div class="waiting-title">Chargement en cours <font style="font-weight:400;">...</font></div>
                    <a class="waiting-text">Veuillez patienter</a>
                </div>
            </div>
        </div>
        <div class="height-center" v-else>
            <error></error>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoaderDefault',
        components: {
            Error,
        },
        props: {
            sessionsError: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style scoped>
  @import '../../assets/css/loading-spinner.css';
</style>